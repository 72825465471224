/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import { Try } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import React from 'react'

const Home = () => {

  return (
    <div>
      <Container sx={{ py: "20vh" }} maxWidth="sm">
        <Typography sx={{ mb: 2 }} variant="h4" align="center">
          Welcome to the edge!
        </Typography>

        <Typography sx={{ mb: 4 }} variant="h6" align="center">
          Use advanced AI services without internet connection
          <sup>1</sup>
          , in your local language
          <sup>2</sup>
        </Typography>

        <Box
          sx={{
          display: "flex",
          justifyContent: "center",
          gridGap: "1rem",
          marginTop: 4
        }}
        >
          <Button
            variant="outlined"
            size="large"
            href="/simulate"
            startIcon={<Try />}
          >
            Try the simulator
          </Button>
        </Box>

        <Typography sx={{ marginTop: 4 }} align="center">
          OR dial 
          {' '}
          <a href="tel:*920*223#">*920*223#</a>
          {' '}
          on all networks in Ghana.
        </Typography>
      </Container>
      <div>
        <Typography sx={{ mb: 2, marginTop:300 }} variant="subtitle2" align="center">
          <sup>1</sup>
          You do need an internet connection to use this website. &quot;Use advanced AI services without internet connection&quot; refers to the USSD service.
          <br />
          <sup>2</sup>
          Not all local languages are supported. Only major ones are provided.
        </Typography>
      </div>
    </div>
  );
}

export default Home
