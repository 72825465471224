/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */
import React from 'react'
import { Container, Link, List, ListItem, ListItemText, Typography } from '@mui/material'

/**
 * Generated by https://getterms.io
 */
const Privacy = () => {
  const appName = 'Edge AI';
  const appOrigin = 'https://edge.mardillu.com';
  const email = `edgeai@mardillu.com`;

  return (
    <Container
      maxWidth="lg"
      sx={{ marginTop: (x) => x.spacing(3) }}
    >
      <Typography align="justify" variant="h3" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography align="justify" paragraph>
        Your privacy is important to us. It is 
        {' '}
        {appName}
        &#39; policy to respect
        your privacy and comply with any applicable law and regulation regarding
        any personal information we may collect about you, including across our
        website, 
        {' '}
        <Link href={appOrigin}>{appOrigin}</Link>
        , and other sites and services we
        own and operate.
      </Typography>
      <Typography align="justify" paragraph>
        This policy is effective as of 28 July 2023 and was last updated on
        28 July 2023.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Information We Collect
      </Typography>
      <Typography align="justify" paragraph>
        Information we collect includes both information you knowingly and
        actively provide us when using or participating in any of our services
        and promotions, and any information automatically sent by your devices
        in the course of accessing our products and services.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Log Data
      </Typography>
      <Typography align="justify" paragraph>
        When you visit our website, our servers may automatically log the
        standard data provided by your web browser. It may include your device’s
        Internet Protocol (IP) address, your browser type and version, the pages
        you visit, the time and date of your visit, the time spent on each page,
        other details about your visit, and technical details that occur in
        conjunction with any errors you may encounter.
      </Typography>
      <Typography align="justify" paragraph>
        Please be aware that while this information may not be personally
        identifying by itself, it may be possible to combine it with other data
        to personally identify individual persons.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        We may ask for personal information which may include one or more of the
        following:
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="1. Phone number" />
        </ListItem>
      </List>
      <Typography align="justify" variant="h4" gutterBottom>
        Legitimate Reasons for Processing Your Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        We only collect and use your personal information when we have a
        legitimate reason for doing so. In which instance, we only collect
        personal information that is reasonably necessary to provide our
        services to you.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Collection and Use of Information
      </Typography>
      <Typography align="justify" paragraph>
        We may collect personal information from you when you do any of the
        following on our website:
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary={`1. Access the ${appName} service via the USSD code *920*223#`} />
        </ListItem>
      </List>
      <Typography align="justify" paragraph>
        We may collect, hold, use, and disclose information for the following
        purposes, and personal information will not be further processed in a
        manner that is incompatible with these purposes:
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary={`1. Access the ${appName} service via the USSD code *920*223#`} />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="2. to enable you to customize or personalize your experience of our
          website and USSD service"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="3. to contact and communicate with you" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="4. for analytics, market research, and business development, including to
          operate and improve our website, associated applications, and
          associated social media platforms"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="5. to enable you to access and use our website, associated applications,
          and associated social media platforms"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="6. for internal record keeping and administrative purposes" />
        </ListItem>
      </List>
      <Typography align="justify" variant="h4" gutterBottom>
        Security of Your Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        When we collect and process personal information, and while we retain
        this information, we will protect it within commercially acceptable
        means to prevent loss and theft, as well as unauthorized access,
        disclosure, copying, use, or modification.
      </Typography>
      <Typography align="justify" paragraph>
        Although we will do our best to protect the personal information you
        provide to us, we advise that no method of electronic transmission or
        storage is 100% secure, and no one can guarantee absolute data security.
        We will comply with laws applicable to us in respect of any data breach.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        How Long We Keep Your Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        We retain your personal information in identifiable form for a maximum of thirty (30) days. Once the need for your personal information has concluded, we will proceed to anonymize it by eliminating all identifying details associated with you.
      </Typography>
      <Typography align="justify" paragraph>
        However, if necessary, we may retain your personal information in identifiable form for our
        compliance with a legal obligation.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Children’s Privacy
      </Typography>
      <Typography align="justify" paragraph>
        We do not aim any of our products or services directly at children under
        the age of 13, and we do not knowingly collect personal information
        about children under 13.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Disclosure of Personal Information to Third Parties
      </Typography>
      <Typography align="justify" paragraph>
        We may disclose personal information to:
        {" "}
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="1. a parent, subsidiary, or affiliate of our company" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="1. to enable you to customize or personalize your experience of our
          website and USSD service"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="2. third party service providers for the purpose of enabling them to
          provide their services, for example, AI service providers, IT service providers, data
          storage, hosting and server providers, advertisers, or analytics
          platforms"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="3. our employees, contractors, and/or related entities" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="4. our existing or potential agents or business partners" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="5. sponsors or promoters of any competition, sweepstakes, or promotion we
          run"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="5. courts, tribunals, regulatory authorities, and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise, or
          defend our legal rights"
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="5. third parties, including agents or sub-contractors, who assist us in
          providing information, products, services, or direct marketing to you
          third parties to collect and process data"
          />
        </ListItem>
      </List>
      <Typography align="justify" variant="h4" gutterBottom>
        International Transfers of Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        The personal information we collect is stored and/or processed where we
        or our partners, affiliates, and third-party providers maintain
        facilities. Please be aware that the locations to which we store,
        process, or transfer your personal information may not have the same
        data protection laws as the country in which you initially provided the
        information. If we transfer your personal information to third parties
        in other countries: (i) we will perform those transfers in accordance
        with the requirements of applicable law; and (ii) we will protect the
        transferred personal information in accordance with this privacy policy.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Your Rights and Controlling Your Personal Information
      </Typography>
      <Typography align="justify" paragraph>
        You always retain the right to withhold personal information from us,
        with the understanding that your experience of our website and USSD service may be
        affected. We will not discriminate against you for exercising any of
        your rights over your personal information. If you do provide us with
        personal information you understand that we will collect, hold, use and
        disclose it in accordance with this privacy policy. You retain the right
        to request details of any personal information we hold about you.
      </Typography>
      <Typography align="justify" paragraph>
        If we receive personal information about you from a third party, we will
        protect it as set out in this privacy policy. If you are a third party
        providing personal information about somebody else, you represent and
        warrant that you have such person’s consent to provide the personal
        information to us.
      </Typography>
      <Typography align="justify" paragraph>
        If you believe that any information we hold about you is inaccurate, out
        of date, incomplete, irrelevant, or misleading, please contact us using
        the details provided in this privacy policy. We will take reasonable
        steps to correct any information found to be inaccurate, incomplete,
        misleading, or out of date.
      </Typography>
      <Typography align="justify" paragraph>
        If you believe that we have breached a relevant data protection law and
        wish to make a complaint, please contact us using the details below and
        provide us with full details of the alleged breach. We will promptly
        investigate your complaint and respond to you, in writing, setting out
        the outcome of our investigation and the steps we will take to deal with
        your complaint. You also have the right to contact a regulatory body or
        data protection authority in relation to your complaint.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Use of Cookies
      </Typography>
      <Typography align="justify" paragraph>
        We use &ldquo;cookies&rdquo; to collect information about you and your
        activity across our site. A cookie is a small piece of data that our
        website stores on your computer, and accesses each time you visit, so we
        can understand how you use our site. This helps us serve you content
        based on preferences you have specified.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Limits of Our Policy
      </Typography>
      <Typography align="justify" paragraph>
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Changes to This Policy
      </Typography>
      <Typography align="justify" paragraph>
        At our discretion, we may change our privacy policy to reflect updates
        to our business processes, current acceptable practices, or legislative
        or regulatory changes. If we decide to change this privacy policy, we
        will post the changes here at the same link by which you are accessing
        this privacy policy.
      </Typography>
      <Typography align="justify" paragraph>
        If required by law, we will get your permission or give you the
        opportunity to opt in to or opt out of, as applicable, any new uses of
        your personal information.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        Contact Us
      </Typography>
      <Typography align="justify" paragraph>
        For any questions or concerns regarding your privacy, you may contact us
        using the following details:
      </Typography>
      <Typography align="justify" paragraph>
        {appName}
        {' '}
        Support Team (
        <Link href={`mailto:${email}`}>{email}</Link>
        )
      </Typography>
    </Container>
  );
}

export default Privacy
