import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './header.module.css'
import Colors from '../Utils/colors'
import HeaderLogo from './HeaderLogo'

function Header() {
    const [menu, setMenu] = useState(false)
    const [active, setActive] = useState('/')
    const location = useLocation()
    
    useEffect(()=>{
        setActive(location.pathname)
    }, [location.pathname])

    return (
      <header className={`sticky top-0 ${style.navs} z-20`}>
        <div className="w-full h-full bg-[#101418]" />
        <nav className="px-10 absolute top-0 left-0 right-0 bottom-0" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <HeaderLogo />
            </Link>
          </div>

          <div className="flex lg:hidden">
            <button onClick={()=>setMenu(true)} type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
              <span className="sr-only">Open main menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>

          <div className="hidden lg:flex lg:gap-x-12 euclid ">
            <span className={`text-md font-bold leading-6 text-[${Colors.primaryText}]`}>Edge AI Simulator (Preview)</span>
          </div>

          <div className="hidden items-center lg:flex lg:flex-1 lg:justify-end lg:gap-x-3 euclid">
            <Link to="/" className={`rounded-lg px-5 py-2 text-md font-${active==='/'?'bold':'regular'} leading-1 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Home</Link>
            <Link to="/about" className={`rounded-lg px-5 py-2 text-md font-${active==='/about'?'bold':'regular'} leading-1 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>About</Link>
            <Link to="/field-test" className={`rounded-lg px-5 py-2 text-md font-${active==='/field-test'?'bold':'regular'} leading-1 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Field Test</Link>
            <Link to="/privacy" className={`rounded-lg px-5 py-2 text-md font-${active==='/privacy'?'bold':'regular'} leading-1 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Privacy</Link>
            <Link to="/terms" className={`rounded-lg px-5 py-2 text-md font-${active==='/terms'?'bold':'regular'} leading-1 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Terms</Link>
          </div>
        </nav>
        {
                    menu && (
                    <>
                        {/* <!-- Mobile menu, show/hide based on menu open state. --> */}
                      <div className="lg:hidden" role="dialog" aria-modal="true">
                        {/* <!-- Background backdrop, show/hide based on slide-over state. --> */}
                        <div className="fixed inset-0 z-50" />
                        <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#101418] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                          <div className="flex items-center justify-between">
                            <Link onClick={() => setMenu(false)} to="/" className="-m-1.5 p-1.5">
                              <HeaderLogo />
                            </Link>
                            <button onClick={()=>setMenu(false)} type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700">
                              <span className="sr-only">Close menu</span>
                              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                          <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                              <div className="space-y-2 py-6">
                                <Link onClick={() => setMenu(false)} to="/" className={`-mx-3 block rounded-lg px-3 py-2 text-base font-${active==='/'?'bold':'regular'} leading-7 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Home</Link>
                                <Link onClick={() => setMenu(false)} to="/about" className={`-mx-3 block rounded-lg px-3 py-2 text-base font-${active==='/about'?'bold':'regular'} leading-7 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>About</Link>
                                <Link onClick={() => setMenu(false)} to="/field-test" className={`-mx-3 block rounded-lg px-3 py-2 text-base font-${active==='/field-test'?'bold':'regular'} leading-7 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Field Test</Link>
                                <Link onClick={() => setMenu(false)} to="/privacy" className={`-mx-3 block rounded-lg px-3 py-2 text-base font-${active==='/privacy'?'bold':'regular'} leading-7 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Privacy</Link>
                                <Link onClick={() => setMenu(false)} to="/terms" className={`-mx-3 block rounded-lg px-3 py-2 text-base font-${active==='/terms'?'bold':'regular'} leading-7 text-[${Colors.primaryText}] hover:bg-[#171d24]`}>Terms</Link>
                              </div>
                              <div className="py-4">
                                © 2023 Edge AI (Preview)
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    )
                }
      </header>
    )
}

export default Header