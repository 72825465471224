import React from 'react'
import logo512Circ from '../../assets/logo512Circ.png'


function HeaderLogo() {
  return (
    <div className="flex">
      <img src={logo512Circ} alt="" width={30} className='mr-2' />
    </div>
  )
}

export default HeaderLogo