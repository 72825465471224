import React from 'react';
import YouTube from 'react-youtube';

export default function YouTubePlay() {
  const videoIds = ['e1gJUH0nNiA', 'gu_calfIVf8'];

  return (
    <div style={{ width: '100%' }}>
      {videoIds.map((videoId, index) => (
        <YouTube
          key={index}
          videoId={videoId}
          opts={{
            position:"absolute",
            margin: "auto",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            playerVars: {
              autoplay: 0, // Change to 1 if you want the videos to autoplay
            },
          }}
        />
      ))}
    </div>
  );
}
