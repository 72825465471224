/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */
import React from 'react'
import { Container, Link, List, ListItem, ListItemText, Typography } from '@mui/material'

/**
 * Generated by https://getterms.io
 */
const About = () => {
  const appName = 'Edge AI';
  const simulator = 'https://edge.mardillu.com/simulate';

  return (
    <Container
      maxWidth="lg"
      sx={{ marginTop: (x) => x.spacing(3) }}
    >
      <Typography align="justify" variant="h3" gutterBottom>
        About Edge AI
      </Typography>
      <Typography align="justify" paragraph>
        This project explores the development of an innovative Edge AI solution aimed at bridging the
        technology gap in rural Africa, a region often constrained by language barriers and limited
        internet connectivity. We propose a system that leverages Unstructured Supplementary
        Service Data (USSD) and Interactive Voice Response (IVR) services, allowing users to engage
        with advanced AI technologies using simple mobile devices and their local languages.
      </Typography>
      <Typography align="justify" paragraph>
        As part of our research preview, we have also designed this simulator for users who are
        unable to utilize the USSD service. This simulator replicates the experience of a basic phone,
        providing an interactive platform for users to familiarize themselves with the USSD interface.
        By offering this research preview, we seek to gather valuable feedback and insights from users
        to refine and improve our Edge AI solution. Through this endeavor, we aim to enable more
        individuals to access and benefit from our AI-driven services, representing a significant
        stride towards utilizing AI for social and economic development in resource-constrained settings
        while ensuring inclusivity and participation in the digital age.
      </Typography>
      <Typography align="justify" variant="h4" gutterBottom>
        How it works
      </Typography>
      <Typography align="justify" paragraph>
        If you are familiar with USSD codes and comfortable with using feature phones, this should be a breeze.
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            1. 
            {' '}
            <Link href={simulator}>Click here</Link>
            {' '}
            to navigate to the simulator
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="2. A test phone number will be assigned to you. Please not that the phone number is fake" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            3. Dial the USSD code 
            {' '}
            <b>*920*223# on the simulated phone</b>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="4. Click on the call button and wait for the response" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText>
            5. Type any prompt 
            {' '}
            <b>in your local language</b>
          </ListItemText>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="6. Type 1 and 3 to move to next and previous pages respectively" />
        </ListItem>
      </List>
      <Typography align="justify" variant="h3" gutterBottom>
        Capabilities
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="1. Will attempt to respond in the language of your prompt" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="2. Will decline inappropriate prompts" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="3. Allows you to navigate forward and back through pages of your response" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="4. If network breaks, redialing *920*223# within 45 seconds will pick up from where you stopped." />
        </ListItem>
      </List>
      <Typography align="justify" variant="h3" gutterBottom>
        Limitations
      </Typography>
      <Typography align="justify" paragraph>
        {appName}
        {' '}
        is in research preview and still being actively developed and improved. It currently has the following limitations.
      </Typography>
      <List sx={{ listStyle: "decimal", pl: 4 }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="1. May display inaccurate or offensive information." />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="2. No support for many African languages yet" />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText primary="3. Responses in some languages may be scrambled and/or appear meaningless" />
        </ListItem>
      </List>
    </Container>
  );
}

export default About
