import React from 'react'
import {
  Box,
  Button,
  CircularProgress, Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, DialogTitle, Link, List, ListItem, ListItemText, Typography
} from '@mui/material'

function DlDialog(
  {
    body,
    title = '',
    open = false,
    isLoader = true,
    positiveButtonText = undefined,
    positiveButtonAction = undefined,
    negativeButtonText = undefined,
    negativeButtonAction = undefined,
  }) {
  const points = [
    "1. A test phone number has been assigned to you. Please note that the phone number is fake",
    "2. Keep in mind that one button can serve multiple purposes under different contexts.",
    "3. Dial the USSD code *920*223# on the simulated phone",
    "4. Click on the 'Call' button and wait for the response",
    "5. Type any prompt in your local language using the lettered buttons. Click a button multiple times " +
    "in quick succession to change the letter",
    "6. Use the 'Drop call' button to delete or clear your input",
    "7. Click on the 'Call' button to initiate a reply. After typing a reply, Click on the 'Call' button to send the reply",
    "8. If your response is paged, reply with 1 and 3 to move to next and previous pages respectively",
    "9. If you encounter issues, use the reset button to start over"];
  return (
    <Dialog
      open={open}
      onClose={() => {
        //setOpen(false);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <Container sx={{ py: "2vh" }}>
        <DialogTitle id="alert-dialog-title" >
          {title}
        </DialogTitle>
      </Container>
      
      <DialogContent sx={{}}>
        {isLoader &&
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </Box>
        }
        <DialogContentText id="alert-dialog-description">
          {body}
          <List dense={false}>
            {points.map((point) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={point}
                      secondary={null}
                    />
                  </ListItem>
                )
              })
            }
          </List>
          <Typography align="center">
            <Link href={'/demo'}>Video demo</Link>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {positiveButtonText &&
          <Button onClick={() => {
            positiveButtonAction()
          }}>{positiveButtonText}</Button>
        }

        {negativeButtonText &&
          <Button onClick={() => {
            negativeButtonAction()
          }}>{negativeButtonText}</Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default DlDialog