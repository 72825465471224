
const Colors = {
    green: '#245100',
    primaryText: '#4472c4',
    bannerColor: '#FFFFFF',
    lightGreen: '#17BEBB',
    lighterGreen: '#D7F4F4',
    lightButton: '#9FE870',
    violet: '#5D00A7',
    gray: '#5C5C5C',
    lightGray: '#878787',
    lightGreenBackground: '#F2FFFF',
}

 export default Colors