import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from 'pages/About'
import './App.css'
import Header from './components/Header/Header'
import Home from './pages/Home'
import FeaturePhone from './pages/Simulator'
import Privacy from './pages/Privacy'
import Terms from './pages/Terms'
import VideoPop from './pages/VideoPop'
import YouTube from 'react-youtube'
import YouTubePlay from './pages/YouTubePlay'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/sim" element={<Home />} />
        <Route path="/simulate" element={<FeaturePhone />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/demo" element={<VideoPop />} />
        <Route path="/field-test" element={<YouTubePlay />} />
      </Routes>
    </div>
  )
}

export default App
