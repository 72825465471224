import * as React from 'react';

export default function VideoPop() {
  return (
    <div style={{width:'100%', }}>
      <video style={{
        position:"absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }} width="300" controls >
        <source src="https://edge.mardillu.com/demo.mp4" type="video/mp4"/>
      </video>
    </div>
  );
}
